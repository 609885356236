import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0,3,4,5,6,7];

export const dictionary = {
		"/(with-navbar)": [~8,[2]],
		"/(with-navbar)/(protected)/admin": [~9,[2,3]],
		"/(with-navbar)/aircraft": [~37,[2]],
		"/(with-navbar)/aircraft/[slug]/[aircraft_id]": [~38,[2]],
		"/(with-navbar)/(protected)/aircraft/[slug]/[aircraft_id]/book": [~10,[2,3]],
		"/(with-navbar)/(protected)/aircraft/[slug]/[aircraft_id]/review": [~11,[2,3]],
		"/(with-navbar)/(protected)/aircraft/[slug]/[aircraft_id]/review/[review_id]": [~12,[2,3]],
		"/(with-navbar)/(protected)/booking": [13,[2,3,4]],
		"/(with-navbar)/(protected)/booking/chat": [14,[2,3,4]],
		"/(with-navbar)/(protected)/connections": [15,[2,3,5]],
		"/(with-navbar)/(protected)/connections/calendar": [~19,[2,3,5]],
		"/(with-navbar)/(protected)/connections/[booking_id]": [16,[2,3,5,6]],
		"/(with-navbar)/(protected)/connections/[booking_id]/chat": [17,[2,3,5,6]],
		"/(with-navbar)/(protected)/connections/[booking_id]/payment": [~18,[2,3,5,6]],
		"/(with-navbar)/contact": [~39,[2]],
		"/(with-navbar)/faq": [~40,[2]],
		"/(with-navbar)/feed": [~41,[2]],
		"/(with-navbar)/(protected)/feed/edit/[post_id]": [~20,[2,3]],
		"/(with-navbar)/(protected)/feed/new": [21,[2,3]],
		"/(with-navbar)/feed/[slug]/[post_id]": [~42,[2]],
		"/(with-navbar)/how-it-works": [~43,[2]],
		"/(with-navbar)/legal": [~44,[2]],
		"/login": [~48],
		"/login/forgot": [49],
		"/(with-navbar)/(protected)/manage-aircraft": [~22,[2,3]],
		"/(with-navbar)/(protected)/manage-aircraft/new": [~25,[2,3]],
		"/(with-navbar)/(protected)/manage-aircraft/[aircraft_id]": [23,[2,3,7]],
		"/(with-navbar)/(protected)/manage-aircraft/[aircraft_id]/pilots": [~24,[2,3,7]],
		"/(with-navbar)/privacy-policy": [~45,[2]],
		"/(with-navbar)/(protected)/profile": [~26,[2,3]],
		"/(with-navbar)/(protected)/profile/instructor": [~27,[2,3]],
		"/(with-navbar)/(protected)/profile/time-builder": [~28,[2,3]],
		"/reset": [~50],
		"/(with-navbar)/search": [~46,[2]],
		"/signup": [~51],
		"/(with-navbar)/(protected)/subscription": [~29,[2,3]],
		"/(with-navbar)/user/[user_id]": [~47,[2]],
		"/(with-navbar)/(protected)/user/[user_id]/review": [~30,[2,3]],
		"/(with-navbar)/(protected)/user/[user_id]/review/[review_id]": [~31,[2,3]],
		"/(with-navbar)/[page_type=pilot_list_page]": [~35,[2]],
		"/(with-navbar)/[page_type=pilot_list_page]/[slug]/[user_id]": [~36,[2]],
		"/(with-navbar)/[airport_slug]/aircraft/[aircraft_id]": [~32,[2]],
		"/(with-navbar)/[airport_slug]/instructor/[instructor_id]": [~33,[2]],
		"/(with-navbar)/[airport_slug]/time-builder/[pilot_id]": [~34,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';